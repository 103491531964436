import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import 'bulma/css/bulma.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainPage from "./components/home";
import Login from "./components/login";
import Logout from "./components/logout";
import axios from "axios";
import cookie from "./libs/cookie";
import OrderPage from "./components/order";

//axios.defaults.baseURL = 'http://water-storage.test/api';
axios.defaults.baseURL = 'https://bonus-water.uz/api';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

// errorComposer will compose a handleGlobally function
const errorComposer = (error) => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401) {
        cookie.removeItem('token');
        window.location.href = 'https://app.bonus-water.uz/login';
    }
};

axios.interceptors.response.use(undefined, (error) => {
    // eslint-disable-next-line no-param-reassign
    error.handleGlobally = errorComposer(error);

    return Promise.reject(error);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/" component={MainPage} />
            <Route path="/login" component={Login} />
            <Route path="/courier/:orderId/order-detail" component={OrderPage} />
            <Route path="/logout" component={Logout} />
        </Switch>
    </Router>,
    document.getElementById('root')
);


serviceWorker.register();
