import React from 'react';

export default () => (
  <div className="skeleton-blink">
    <div className="tile is-ancestor">
      <div className="tile is-parent is-vertical is-12">
        <article className="header tile is-child notification is-text" />
      </div>
    </div>
    <div className="tile is-ancestor">
      <div className="tile is-parent is-vertical is-12">
        <article className="header tile is-child notification is-text" />
      </div>
    </div>
    <div className="tile is-ancestor">
      <div className="tile is-parent is-vertical is-12">
        <article className="header tile is-child notification is-text" />
      </div>
    </div>
    <div className="tile is-ancestor">
      <div className="tile is-parent is-vertical is-12">
        <article className="header tile is-child notification is-text" />
      </div>
    </div>
    <div className="tile is-ancestor">
      <div className="tile is-parent is-vertical is-12">
        <article className="header tile is-child notification is-text" />
      </div>
    </div>
  </div>
);
