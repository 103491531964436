import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import GuestLayout from './app-layout';
import cookie from '../libs/cookie';
import Authenticator from './fake-authenticator';
import {Button, Spinner} from "react-bootstrap";

export default class Login extends React.Component {
  onLoginRedirectUrl = '/';

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loggedIn: false,
      error: '',
      errorMsg: '',
      isLoading: false
    };
  }

  componentDidMount() {
    const isLoggedIn = Authenticator.isLoggedIn();
    if (isLoggedIn) {
      this.setState({
        loaded: true,
        loggedIn: true,
      });
    } else {
      this.setState({
        loaded: true,
      });
    }
  }

  handleSubmit(e) {
    if (e && e.preventDefault) e.preventDefault();
    const loginData = new FormData(e.target);

    loginData.set('user_agent', navigator.userAgent);

    this.setState({
      isLoading: true
    });

    axios.post('/courier/get-token', loginData)
      .then((response) => {
        this.setState({
          isLoading: false
        });

        if (response.data.data.token) {
          cookie.setItem('token', response.data.data.token);

          this.setState({
            loggedIn: true,
            error: '',
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });

        cookie.removeItem('token');
        this.setState({
          error: 'error',
          errorMsg: 'Login yoki Paro`l noto`g`ri',
        });
      });
  }

  render() {
    const {
      loggedIn,
      error,
      errorMsg,
      loaded,
    } = this.state;
    if (!loaded) return null;
    if (loggedIn) {
      return <Redirect push={false} to={this.onLoginRedirectUrl} />;
    }
    return (
      <GuestLayout>
        <div className="is-centered p-t-xl p-r-md p-l-md">
          <div className="column container">
            <div className="box">
              <h1 className="title">Kirish formasi</h1>
              <form onSubmit={e => this.handleSubmit(e)}>
                <div className="field">
                  <label className="label" htmlFor="username">
                    Login
                    <div className="control">
                      <input
                        id="username"
                        name="login"
                        className={`input ${error === 'username' ? 'is-danger' : ''}`}
                        type="text"
                        placeholder="Username input"
                      />
                    </div>
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor="password">
                    Paro'l
                    <div className="control">
                      <input
                        id="password"
                        name="password"
                        className={`input ${error === 'password' ? 'is-danger' : ''}`}
                        type="password"
                        placeholder="********"
                      />
                    </div>
                  </label>
                </div>
                <div className="field is-grouped">
                  <div className="control">
                    {this.state.isLoading && (
                        <Button variant="primary" disabled>
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          />
                        </Button>
                    )}
                    {!this.state.isLoading && <Button type="submit" variant="primary">Kirish</Button>}
                  </div>
                </div>
                {
                  error !== '' && (
                    <p className="help is-danger">
                      {errorMsg}
                    </p>
                  )
                }
              </form>
            </div>
          </div>
        </div>
      </GuestLayout>
    );
  }
}
