import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Authenticator from './fake-authenticator';

export default class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleMenuBar(e) {
    const { open } = this.state;
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.setState({
      open: !open,
    });
  }

  closeMenuBar() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <div>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className=" navbar-item"><strong>Bonus Water</strong></Link>
              {
                Authenticator.isLoggedIn() && (
                  <button
                    type="button"
                    onClick={e => this.toggleMenuBar(e)}
                    className={`navbar-burger ${open ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded="false"
                    style={{
                      background: 'none',
                      border: 'none',
                      outline: 'none',
                    }}
                  >
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                  </button>
                )
              }
            </div>
            <div className={`navbar-menu ${open ? 'is-active' : ''}`}>
              {
                Authenticator.isLoggedIn() && (
                  <Link className="navbar-item" to="/" onClick={() => this.closeMenuBar()}>
                    Buyurtmalar
                  </Link>
                )
              }
              {
                Authenticator.isLoggedIn() && (
                  <Link to="/logout" className="navbar-item" onClick={() => this.closeMenuBar()}>
                    Chiqish
                  </Link>
                )
              }
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
