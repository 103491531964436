import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Protected from './protected';
import AppLayout from './app-layout';
import axios from 'axios';
import cookie from '../libs/cookie';
import skeleton from './skeleton-order';
import {Tab, Table, Tabs, Form, Button, Modal, Alert, Spinner} from 'react-bootstrap';
import { Map, Placemark, YMaps } from 'react-yandex-maps';

const OrderCompletedModal = (props) => {
  const handleClose = () => {
    props.close();
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const payment = props.payments.filter((item) => {
    return props.savedData.paymentType == item.id;
  }).shift();

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Kiritilgan ma'lumotlar to'g'rimi?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <b>To'lov turi:</b> {payment ? payment.name : ''}
          </div>
          <div className="mb-2">
            <b>To'lov summasi:</b> {numberWithCommas(String(props.savedData.paymentAmount))} so'm
          </div>
          <div>
            <b>Qaytarib olinyotgan tara soni:</b> {props.savedData.taraReturn ? props.savedData.taraReturn : 0} ta
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.isLoading && (
              <Button variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
              </Button>
          )}
          {!props.isLoading && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Yo'q
                </Button>
                <Button variant="primary" onClick={props.send}>Ha to'g'ri</Button>
              </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default class OrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      payments: [],
      loaded: false,
      orderCompleted: false,
      errorLoadData: false,
      redirect: false,
      orderCompletedData: {
        paymentType: null,
        paymentAmount: null,
        taraReturn: null,
      },
      errorInputs: {
        paymentType: null,
        paymentAmount: null,
      },
      isLoading: false
    };
  }

  componentDidMount() {
    console.log(this.props);

    if (cookie.hasItem('token')) {
      axios.defaults.headers.common.Authorization = `Bearer ${cookie.getItem('token')}`;
    }
    const promiseOrder = axios.get(`/courier/order/${this.props.match.params.orderId}`)
      .then((response) => {
        if (response.data.completed) {
          this.setState({
            orderCompleted: true,
          });
        } else {
          const order = response.data.data;
          this.setState({
            order,
            orderCompletedData: {
              ...this.state.orderCompletedData, paymentAmount: order.total_price,
            },
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorLoadData: true,
        });
      });

    const promisePayment = axios.get('/payments')
      .then((response) => {
        const payments = response.data.data;
        this.setState({
          payments,
        });
      });

    Promise.all([promiseOrder, promisePayment]).then((values) => {
      this.setState({ loaded: true });
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  validateData = () => {
    let fields = this.state.orderCompletedData;
    let paymentType = this.state.errorInputs.paymentType;
    let paymentAmount = this.state.errorInputs.paymentAmount;

    if (fields.paymentType === null) {
      paymentType = true;
    } else {
      paymentType = false;
    }

    if (fields.paymentAmount === null) {
      paymentAmount = true;
    } else {
      paymentAmount = false;
    }

    this.setState({ errorInputs: { ...this.state.errorInputs, paymentAmount: paymentAmount, paymentType: paymentType } });
  }

  validateInput(value) {
    if (value != '') {
      return parseFloat(value);
    }

    return null;
  }

  paymentType = (input) => {
    let value = this.validateInput(input.currentTarget.value);

    this.setState({
      orderCompletedData: {
        ...this.state.orderCompletedData, paymentType: value,
      },
    });
  }

  paymentSum = (input) => {
    let value = this.validateInput(input.currentTarget.value);

    this.setState({
      orderCompletedData: {
        ...this.state.orderCompletedData, paymentAmount: value,
      },
    });
  }

  returnTara = (input) => {
    let value = this.validateInput(input.currentTarget.value);

    this.setState({
      orderCompletedData: {
        ...this.state.orderCompletedData, taraReturn: value,
      },
    });
  }

  isValidPaymentAmount = () => {
    return this.state.errorInputs.paymentAmount === true;
  }

  isValidPaymentType = () => {
    return this.state.errorInputs.paymentType === true;
  }

  onClose = () => {
    this.setState({ errorInputs: { ...this.state.errorInputs, paymentAmount: null, paymentType: null } });
  }

  sendData = () => {
    const data = this.state.orderCompletedData;

    this.setState({
      isLoading: true
    });

    axios.put(`/courier/${this.props.match.params.orderId}/order-complete`, data)
      .then((response) => {
        this.setState({ redirect: true, isLoading: false });
      });
  }

  render() {
    const order = this.state.order;
    const payments = this.state.payments;

    if (this.state.redirect) {
      return (
        <Route render={({ staticContext }) => {
          if (staticContext) staticContext.status = 302;
          return <Redirect to="/" />;
        }}
        />
      );
    }

    return (
      <Protected>
        <AppLayout>
          <div className="container">
            {this.state.loaded && this.state.orderCompleted && (
              <Alert variant="warning" className="text-center">
                Buyurtma yetkazib berib bo'lingan.
              </Alert>
            )}
            {this.state.loaded && this.state.errorLoadData && (
              <Alert variant="danger" className="text-center">
                Hatolik yuz berdi.
              </Alert>
            )}
            {this.state.loaded && !this.state.orderCompleted && !this.state.errorLoadData && (
              <div>
                <div className="modal-card-title mb-4 text-white font-weight-bold">Buyurtma №{order.id}</div>
                <Tabs defaultActiveKey="order">
                  <Tab eventKey="order" title="Batafsil">
                    <div className="p-3">
                      <div className="mb-2">
                        <b>Mijoz:</b> {order.customer.name} {order.customer.company_name ? (`/ ${order.customer.company_name}`) : ''}
                      </div>
                      <div className="mb-2">
                        <b>Telefon:</b> {order.customer.phone}
                      </div>
                      <div className="mb-2">
                        <b>Mijoz balansi:</b> {this.numberWithCommas(order.customer.balance)} so'm
                      </div>
                      <div className="mb-2">
                        <b>Buyurtmaga izoh:</b> {order.comment}
                        <br/>
                        <br/>
                      </div>
                      <div className="mb-2">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr className="text-center">
                              <th>Tovar</th>
                              <th>Dona</th>
                              <th>Narxi (dona)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.order_products.map((prod) => {
                              return (
                                <tr className="text-center">
                                  <td>{prod.product ? prod.product.name : ''}</td>
                                  <td>{prod.amount}</td>
                                  <td>{this.numberWithCommas(prod.price)}</td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td className="text-center"><b>Jami:</b></td>
                              <td colSpan="2" className="text-center">{this.numberWithCommas(order.total_price)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="mb-2">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label><b>To'lov turini tanlang:</b></Form.Label>
                          <Form.Control as="select" onChange={this.paymentType} isInvalid={this.isValidPaymentType()}>
                            <option value="">-</option>
                            {payments.map((item) => {
                              return <option value={item.id}>{item.name}</option>;
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="mb-2">
                        <Form.Group>
                          <Form.Label><b>To'lov summasi:</b></Form.Label>
                          <Form.Control type="number" onChange={this.paymentSum} value={this.state.orderCompletedData.paymentAmount} placeholder="0.00" isInvalid={this.isValidPaymentAmount()} />
                        </Form.Group>
                      </div>
                      <div className="mb-2">
                        <Form.Group>
                          <Form.Label><b>Nechta tara qaytarib olindi:</b></Form.Label>
                          <Form.Control type="number" onChange={this.returnTara} placeholder="0" />
                        </Form.Group>
                      </div>
                      <div className="mb-2">
                        <Button variant="primary" className="w-100" onClick={this.validateData}>
                          Buyurtmani bajarish
                        </Button>
                        <OrderCompletedModal
                          savedData={this.state.orderCompletedData}
                          payments={this.state.payments}
                          close={this.onClose}
                          show={this.state.errorInputs.paymentAmount === false && this.state.errorInputs.paymentType === false}
                          send={this.sendData}
                          isLoading={this.state.isLoading}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="customer" title="Manzil">
                    <div className="p-3">
                      <div className="mb-2">
                        <b>Manzil:</b> {order.customer.address}
                      </div>
                      <div className="mb-2">
                        <b>Orientir:</b> {order.customer.landmark}
                      </div>
                      {order.customer.coords && (
                        <div className="mb-2">
                          <b>Manzil xaritada:</b>
                          <YMaps>
                            <Map defaultState={{ center: [order.customer.coords.lat, order.customer.coords.long], zoom: 12 }}>
                              <Placemark geometry={[order.customer.coords.lat, order.customer.coords.long]} />
                            </Map>
                          </YMaps>
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            )}
            {!this.state.loaded && skeleton()}
          </div>
        </AppLayout>
      </Protected>
    );
  }
}
