import React from 'react';
import { Link } from 'react-router-dom';
import Protected from './protected';
import AppLayout from './app-layout';
import axios from 'axios';
import cookie from '../libs/cookie';
import { Accordion, Alert, Button, Card } from 'react-bootstrap';
import skeleton from './skeleton';

export default class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      districts: [],
      loaded: false,
    };
  }

  componentDidMount() {
    if (cookie.hasItem('token')) {
      axios.defaults.headers.common.Authorization = `Bearer ${cookie.getItem('token')}`;
    }
    const promiseDistrict = axios.get('/districts')
      .then((response) => {
        const districts = response.data.data;
        this.setState({
          districts,
        });
      });

    const promiseOrder = axios.get('/courier/active-orders')
      .then((response) => {
        const orders = response.data.data;
        this.setState({
          orders,
        });
      });

    Promise.all([promiseDistrict, promiseOrder]).then((values) => {
      this.setState({ loaded: true });
    });
  }

  getOrdersList = () => {
    let list = [];
    let ind = 1;

    for (const key in this.state.orders) {
      const district = this.state.districts.filter((item) => {
        return parseInt(item.id) === parseInt(key);
      }).shift();

      const orders = this.state.orders[key].map((order, index) => {
        return (
          <Card key={order.id} className="mb-2">
            <Card.Body className="pl-3 pr-3 pt-3 pb-3">
              <Card.Subtitle className="mb-2 text-muted">#{order.id} / Suv soni: {order.order_product.amount}</Card.Subtitle>
              <Card.Title>{order.customer.name} {order.customer.company_name ? (`/ ${order.customer.company_name}`) : ''}</Card.Title>
              <Card.Text>
                <b>Manzil:</b> {order.customer.address} <br/>
                <b>Orientir:</b> {order.customer.landmark}
              </Card.Text>
              <Link to={`/courier/${order.id}/order-detail`} className="d-inline-block float-right">
                <Button>Batafsil</Button>
              </Link>
            </Card.Body>
          </Card>
        );
      });

      list.push(
        <Card key={key}>
          <Accordion.Toggle as={Card.Header} eventKey={ind}>
            {district.title}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={ind}>
            <Card.Body className="pl-2 pr-2">{orders}</Card.Body>
          </Accordion.Collapse>
        </Card>,
      );
      ++ind;
    }

    if (list.length < 1) {
      return (
        <Alert variant="warning" className="text-center">
          Buyurtma yo'q
        </Alert>
      );
    }

    return list;
  };

  render() {
    return (
      <Protected>
        <AppLayout>
          <div className="container">
            <div className="modal-card-title mb-4 text-white font-weight-bold">Buyurtmalar</div>

            {this.state.loaded && (
              <Accordion defaultActiveKey={1}>
                {this.getOrdersList()}
              </Accordion>
            )}
            {!this.state.loaded && skeleton()}
          </div>
        </AppLayout>
      </Protected>
    );
  }
}
